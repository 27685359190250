import { Modal, Button } from 'react-bootstrap';
import { translate } from '@crystaldelta/loree-util-i18n-react';
import './linkConfirmationModal.scss';
import { ReactComponent as WarningIcon } from '../../assets/icons/loree-exclamation-circle.svg';
import { ReactComponent as CloseIcon } from '../../assets/icons/loree-close.svg';

interface LinkConfirmationModalProps {
  show: boolean;
  onClose: () => void;
  onConfirm: () => void;
}

export function LinkConfirmationModal({ show, onClose, onConfirm }: LinkConfirmationModalProps) {
  return (
    <Modal
      show={show}
      onHide={onClose}
      centered
      className='link-modal'
      dialogClassName='link-modal-dialog'
    >
      <Modal.Header className='link-modal-header'>
        <Modal.Title className='link-modal-title'>
          <span className='link-modal-icon-wrapper'>
            <WarningIcon />
          </span>
          Warning
        </Modal.Title>
        <button className='link-modal-close' onClick={onClose}>
          <CloseIcon />
        </button>
      </Modal.Header>
      <Modal.Body className='link-modal-body'>
        {String(translate('modal.selectedelementisalreadylinked'))}
      </Modal.Body>
      <Modal.Footer className='link-modal-footer'>
        <Button
          variant='light'
          onClick={onClose}
          className='link-modal-button link-modal-button-secondary'
        >
          Cancel
        </Button>
        <Button
          variant='primary'
          onClick={onConfirm}
          className='link-modal-button link-modal-button-primary'
        >
          Yes, Change
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
