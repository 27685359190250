/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import React, { useEffect, useState } from 'react';
import { getUserInteractives, userVerify } from '../../middleware/api';
import { initializeReactGA } from '../../middleware/googleAnalytics';
import ContentList from './index';
import {
  getFeaturesList,
  getAdminDashboardFontFamilyList,
  getCustomHeaderFontDetails,
} from './utils';
import { RouteComponentProps } from 'react-router-dom';
import { setSharedAccountId } from '../../middleware/utils';

const UserInteractives = (props: RouteComponentProps) => {
  const { id, user } = props.match.params as { id: string; user: string };
  const [userInteractives, setUserInteractives] = useState([]);
  const [config, setConfig] = useState({});
  const [fontFamilyList, setFontFamilyList] = useState({});
  const [customHeaderStyleList, setCustomHeaderStyleList] = useState({});
  const [fetching, setFetching] = useState<boolean>(true);

  useEffect(() => {
    try {
      const fetchData = async () => {
        initializeReactGA(props.location.pathname);
        sessionStorage.setItem('user', user);
        if (!sessionStorage.getItem('sharedAccount')) {
          setSharedAccountId(props.location.search);
        }
        if ((!sessionStorage.getItem('org_id') || !sessionStorage.getItem('user_id')) && id) {
          const userDetails = await userVerify(id);
          sessionStorage.setItem('org_id', userDetails.orgid);
          sessionStorage.setItem('user_id', userDetails.userid);
          sessionStorage.setItem('trackingId', userDetails.trackingId);
          sessionStorage.setItem('loreeVersion', userDetails.version);
        }
        if (!id) {
          props.history.push('/401');
        }
        setFetching(true);
        const interactiveLists = await getUserInteractives(id);
        let config = interactiveLists.config ? JSON.parse(interactiveLists?.config) : null;
        const customLinkStyleList = config?.configsList?.customLinkStyle?.customLink;
        config = getFeaturesList(config, 'user');

        setFetching(false);
        const featureConfig = JSON.parse(interactiveLists?.config ?? 'null');
        const fontFamilyList = getAdminDashboardFontFamilyList(featureConfig?.configsList);
        const customHeaderStyleList = getCustomHeaderFontDetails(featureConfig?.configsList);
        config.customLinkStyleList = customLinkStyleList;

        setUserInteractives(interactiveLists.userInteractives ?? []);
        setConfig(config ?? {});
        setFontFamilyList(fontFamilyList);
        setCustomHeaderStyleList(customHeaderStyleList);
      };
      fetchData();
    } catch (error) {
      console.log('error in fetching Interactive lists');
    }
  }, [id, user, props]);

  return (
    <ContentList
      interactiveLists={userInteractives}
      history={props.history}
      location={props.location}
      config={config}
      fontFamilyList={fontFamilyList}
      customHeaderStyleList={customHeaderStyleList}
      fetching={fetching}
    />
  );
};

export default UserInteractives;
