/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { FontFamilyButtonStyle, FontFamilyOption } from './styledContents';
import { CustomFontsType, FontFamilyButtonProps } from './lintEditorType';
import { CONSTANTS } from './constants';

export function CustomFontFamilySelector(props: FontFamilyButtonProps) {
  const { customFontList, fontName, onFontFamilyChange } = props;
  const fontFamilyArray = CONSTANTS.LINT_FONT_LIST as CustomFontsType[];

  const defaultFontStyles = 'Lato';

  const lintFontList = [...customFontList, ...fontFamilyArray];
  const filteredFontList = lintFontList.filter(
    (item, index, self) => self.findIndex((font) => font.name === item.name) === index,
  );
  const sortedFontList = filteredFontList.sort((fontA, fontB) =>
    fontA.name.localeCompare(fontB.name),
  );

  return (
    <FontFamilyButtonStyle
      value={fontName ? fontName : defaultFontStyles}
      aria-label='fontFamily'
      data-testid='font-family-select'
      onChange={(e: string) => onFontFamilyChange(e)}
      className='fontFamilySelect fontFamilyDropdown'
      render={(fontFamilyArraySize: number) => {
        return (
          <div>
            {sortedFontList[fontFamilyArraySize]?.name ?? fontName ? fontName : defaultFontStyles}
          </div>
        );
      }}
    >
      {sortedFontList.map((font, index) => (
        <FontFamilyOption
          key={`${font.name + '_' + index}`}
          data-testid='font-family-select-option'
          value={font.fontFamilyValue ?? font.name}
        >
          <span title={font.name} data-toggle='tooltip' data-placement='right'>
            {font.name}
            {font.fontStyle ? ' - ' + font.fontStyle : ''}
          </span>
        </FontFamilyOption>
      ))}
    </FontFamilyButtonStyle>
  );
}
