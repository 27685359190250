import { Mark, mergeAttributes } from '@tiptap/core';
import { keepFirstDuplicateCssProperty, setCssProperty } from './utilityFunctions';

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    link: {
      setLink: (attributes: {
        href: string;
        target?: string | null;
        rel?: string | null;
        class?: string | null;
        styles?: string;
      }) => ReturnType;
      unsetLink: () => ReturnType;
      setLinkColor: (color: string) => ReturnType;
    };
  }
}
export interface LinkOptions {
  HTMLAttributes: Record<string, unknown>;
  /**
   * A validation function that modifies link verification for the auto linker.
   * @param url - The url to be validated.
   * @returns - True if the url is valid, false otherwise.
   */
  validate?: (url: string) => boolean;
}
export const CustomizedAnchorElement = Mark.create<LinkOptions>({
  name: 'link',

  priority: 1003,

  keepOnSplit: false,

  inclusive() {
    return false;
  },

  addOptions() {
    return {
      openOnClick: true,
      linkOnPaste: true,
      autolink: true,
      protocols: [],
      HTMLAttributes: {
        target: '_self',
        rel: 'noopener noreferrer nofollow',
      },
      validate: undefined,
    };
  },

  addAttributes() {
    return {
      href: {
        default: null,
      },
      title: {
        default: null,
      },
      target: {
        default: this.options.HTMLAttributes['target'],
      },
      styles: {
        default: '',
        parseHTML: (element) => element.getAttribute('style') ?? '',
        renderHTML: (attributes) => {
          return attributes.styles ? { style: attributes.styles } : {};
        },
      },
    };
  },

  parseHTML() {
    return [{ tag: 'a[href]:not([href *= "javascript:" i])' }];
  },

  renderHTML({ HTMLAttributes }) {
    let mergedAttributes = mergeAttributes(this.options.HTMLAttributes, HTMLAttributes);
    mergedAttributes.style = keepFirstDuplicateCssProperty(mergedAttributes.style);
    return ['a', mergedAttributes, 0];
  },

  addCommands() {
    return {
      setLink:
        (attributes) =>
        ({ chain }) => {
          return chain().setMark(this.name, attributes).setMeta('preventAutolink', true).run();
        },
      unsetLink:
        () =>
        ({ chain }) => {
          return chain()
            .unsetMark(this.name, { extendEmptyMarkRange: true })
            .setMeta('preventAutolink', true)
            .run();
        },
      setLinkColor:
        (color) =>
        ({ chain, editor }) => {
          const linkAttrs = editor.getAttributes('link');
          const updatedStyles = setCssProperty(linkAttrs.styles || '', 'color', color);
          return chain()
            .updateAttributes('link', {
              ...linkAttrs,
              styles: updatedStyles,
            })
            .run();
        },
    };
  },
});
