/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import {
  Editor,
  NodeViewRenderer,
  isTextSelection,
  mergeAttributes,
  Attribute,
} from '@tiptap/core';
import { Level } from '@tiptap/extension-heading';
import { Attrs, Node as ProseMirrorNode, ResolvedPos } from 'prosemirror-model';
import { EditorState, NodeSelection, Selection } from 'prosemirror-state';
import {
  CustomHeaderStyleType,
  EntireTextSelection,
  FontSizeData,
  LoreeInteractiveEditorDashboardContentType,
  ValueAndUnit,
} from '../editorUtilityFunctions/lintEditorType';
import { hideLinkInfoPopup } from '../../linkPopup/showLinkInfoPopup';
import * as ReactDOM from 'react-dom/client';
import { LinkConfirmationModal } from '../../utils/linkConfirmationModal';

export interface SelectionNodeDetails {
  node: ProseMirrorNode;
  start: number;
  end: number;
  index?: number;
  indexAfter?: number;
  before?: number;
  after?: number;
}

export interface SelectionState {
  selectedNode: SelectionNodeDetails | null;
  parentNodes: SelectionNodeDetails[];
  selection: Selection | null;
}

export let editorConfigCopy = {};

export function setEditorConfig(editorConfig: LoreeInteractiveEditorDashboardContentType) {
  if (!editorConfig) {
    editorConfigCopy = {};
    return null;
  }
  editorConfigCopy = {
    customHeaderStyleList: editorConfig?.customHeaderStyleList ?? [],
    fontFamilyList: editorConfig?.fontFamilyList ?? [],
  };
}

export function getRenderedAttributes(node: ProseMirrorNode, editor: Editor) {
  const attr = editor.extensionManager.attributes.filter((i) => i.type === node.type.name);
  return attr
    .filter((item) => item.attribute.rendered)
    .map((item) => {
      if (!item.attribute.renderHTML) {
        return {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          [item.name]: node.attrs[item.name],
        };
      }

      return item.attribute.renderHTML(node.attrs) ?? {};
    })
    .reduce((attributes, attribute) => mergeAttributes(attributes, attribute), {});
}

export function getSelectionNodePath(pos: ResolvedPos): SelectionNodeDetails[] {
  const nodePath: SelectionNodeDetails[] = [];

  for (let i = pos.depth; i > 0; i--) {
    nodePath.push({
      node: pos.node(i),
      start: pos.start(i),
      end: pos.end(i),
      index: pos.index(i),
      indexAfter: pos.indexAfter(i),
      before: pos.before(i),
      after: pos.after(i),
    });
  }
  nodePath.reverse();
  return nodePath;
}

export function createEmptySelectionState(): SelectionState {
  return {
    selectedNode: null,
    parentNodes: [],
    selection: null,
  };
}

export function createSelectionState(selection: Selection): SelectionState {
  if (selection instanceof NodeSelection) {
    return {
      selectedNode: {
        node: selection.node,
        start: selection.from,
        end: selection.to,
      },
      parentNodes: getSelectionNodePath(selection.$from),
      selection: selection,
    };
  }

  const n = selection.$head.parent;
  if (!n) {
    return createEmptySelectionState();
  }

  return {
    selectedNode: {
      node: n,
      start: selection.from,
      end: selection.to,
    },
    parentNodes: getSelectionNodePath(selection.$from),
    selection: selection,
  };
}

export function reconcileAttributes(
  element: HTMLElement,
  newAttributes: Record<string, string>,
  recreateOnChange: string[],
  removables: string[],
): boolean {
  if (!recreateOnChange.every((f) => element.getAttribute(f) === newAttributes[f])) {
    return false;
  }

  const existingAttributes = element.getAttributeNames();
  existingAttributes.forEach((a) => {
    if ((newAttributes[a] === undefined || newAttributes[a] === null) && removables.includes(a)) {
      element.removeAttribute(a);
    }
  });

  Object.keys(newAttributes).forEach((k) => {
    if (newAttributes[k] !== null) {
      element.setAttribute(k, newAttributes[k]);
    }
  });
  return true;
}

export function optimizeStyle(attr: Attrs): Attrs {
  const div = document.createElement('div');
  let updatedAttrs = { ...attr };
  if ((attr['style'] ?? '') !== '') {
    div.setAttribute('style', attr['style']);
    div.style.removeProperty('text-decoration');
    updatedAttrs = { ...updatedAttrs, style: div.style.cssText };
  }
  if (attr['class'] && attr['class'] !== '') {
    const array = attr['class'].split(' ');
    const updatedClass = new Set(array);
    updatedAttrs = { ...updatedAttrs, class: Array.from(updatedClass).join(' ') };
  }
  div.remove();
  return updatedAttrs;
}

export function filterSpans(attr: Record<string, number | string | null>) {
  const newAttr = { ...attr };
  if (newAttr['colspan'] === 1 || newAttr['colspan'] === '1') {
    delete newAttr['colspan'];
  }
  if (newAttr['rowspan'] === 1 || newAttr['rowspan'] === '1') {
    delete newAttr['rowspan'];
  }

  return newAttr;
}

export function simpleElementNodeView(elementType: string): NodeViewRenderer {
  return ({ node, HTMLAttributes, editor, extension }) => {
    const container = document.createElement(
      elementType === 'heading' && node.type.name === 'heading'
        ? `h${node.attrs['level']}`
        : elementType,
    );
    const mergedAttributes = optimizeStyle(
      filterSpans(mergeAttributes(extension.options.HTMLAttributes, HTMLAttributes)),
    );
    Object.keys(mergedAttributes).forEach((k) => {
      if (mergedAttributes[k] !== null) {
        container.setAttribute(k, mergedAttributes[k] as string);
      }
    });

    let attrs: Record<string, any> = extension.options.HTMLAttributes ?? {};

    return {
      dom: container,
      contentDOM: container,
      update: (newNode, decorations, innerDecorations) => {
        // If the node type changed, we need to destroy the element
        if (node.type.name !== newNode.type.name) {
          return false;
        }
        // check the levels for heading, if the level changed, we need to destroy the element
        if (node.type.name === 'heading' && node.attrs['level'] !== newNode.attrs['level'])
          return false;

        // If the attributes are the same as last time, we don't need to do anything
        if (JSON.stringify(attrs) === JSON.stringify(newNode.attrs)) {
          return true;
        }

        let decoratedAttributes = {};

        // Go through all the applied decorations and collect the attributes being applied from them
        // the any is required since the types for decorations are not correct
        decorations.forEach((d: any) => {
          decoratedAttributes = mergeAttributes(decoratedAttributes, d.type.attrs);
        });

        // Save the node attributes to avoid re-rendering the node if the attributes haven't changed
        // for next time
        attrs = newNode.attrs;

        // Merge the attributes from the decorations with the attributes from the node
        const newRenderedAttributes = optimizeStyle(
          filterSpans(
            mergeAttributes(
              extension.options.HTMLAttributes, // Base from the extension
              getRenderedAttributes(newNode, editor), // Attributes from the node
              decoratedAttributes, // Attributes from the decorations
            ),
          ),
        );

        // Apply any updated attributes to the DOM
        return reconcileAttributes(
          container,
          newRenderedAttributes,
          [],
          ['style', 'colspan', 'rowspan'],
        );
      },
    };
  };
}

export const splitUnit = (value: string | undefined | null) => {
  return (
    value
      ?.trim()
      .split(/\d+/g)
      .filter((n) => n)
      .pop()
      ?.trim() ?? 'px'
  );
};

export function styleAttribute(attrName: string, styleName: string) {
  return {
    default: null,
    parseHTML: (element) => {
      if (element.style.getPropertyValue(styleName)) {
        return element.style.getPropertyValue(styleName);
      }
      return undefined;
    },
    renderHTML: (attributes) => {
      if (!attributes[attrName]) {
        return {};
      }

      return { style: `${styleName}: ${attributes[attrName]}` };
    },
  } as Attribute;
}

export function splitValueAndUnit(value: string | undefined | null): ValueAndUnit | null {
  if (!value) return null;
  const f = parseFloat(value);
  const unit = splitUnit(value);
  if (isNaN(f)) {
    return null;
  }

  return { value: f.toString(), unit: unit === '.' ? 'px' : unit };
}

const getElementProp = (typeName: string, property: string, editor: Editor) => {
  const { from, to } = editor.state.selection;
  let value = '';
  editor.state.doc.nodesBetween(from, to, (node: { type: { name: string } }, pos: number) => {
    if (node.type.name === typeName) {
      const nodeElement = editor.view.nodeDOM(pos);
      value = window.getComputedStyle(nodeElement as HTMLElement).getPropertyValue(property);
      return false;
    }
    return true;
  });
  return value;
};

const getIconFontSize = (editor: Editor): string => {
  let iconFontSize = '';
  (editor.state.selection as NodeSelection).node?.descendants(
    (node: { type: { name: string } }) => {
      if (node.type.name === 'iconTag') {
        iconFontSize = getElementProp(node.type.name, 'font-size', editor);
      }
    },
  );
  return iconFontSize;
};

export const getFontStyleData = (
  fontStyleInitValue: string,
  fontStyleIndex: string,
  editor: Editor,
): string => {
  let fontStyleDataResult = fontStyleInitValue;
  let isSel = isTextSelection(editor.state.selection)
    ? editor.state.doc.resolve(editor.state.selection.$anchor.start()).pos
    : editor.state.selection.$anchor.pos;
  isSel =
    editor.state.selection instanceof NodeSelection ? editor.state.selection.$anchor.pos : isSel;
  if (!isTextSelection(editor.state.selection)) {
    //for icon font size
    fontStyleDataResult = getIconFontSize(editor);
  } else {
    const view = editor.view.domAtPos(isSel);
    if (view.node) {
      if (fontStyleIndex === 'font-size') {
        fontStyleDataResult = (view.node as HTMLElement).style.fontSize;
      }
      if (fontStyleIndex !== 'font-size' || fontStyleDataResult === '') {
        fontStyleDataResult = window
          .getComputedStyle(view.node as HTMLElement)
          .getPropertyValue(fontStyleIndex);
      }
    }
  }
  return fontStyleDataResult;
};

const isFloat = (n: number) => {
  return Number(n) === n && n % 1 !== 0;
};

export const getFontSize = (editor: Editor): FontSizeData | null => {
  const ff = editor.getAttributes('textStyle');
  if (ff['fontSize'] && !Number.isNaN(ff['fontSize'].size)) return ff['fontSize'];
  const fontSizeInPx = getFontStyleData('', 'font-size', editor);
  const fontMeta = splitValueAndUnit(fontSizeInPx);
  if (!fontMeta?.value || !fontMeta.unit) return null;
  let fontSize = JSON.parse(fontMeta.value);
  if (isFloat(JSON.parse(fontMeta.value))) {
    fontSize = JSON.parse(fontMeta.value).toFixed(2);
  }
  return { size: fontSize, unit: fontMeta.unit as 'px' | 'pt' };
};

export const getLineHeight = (editor: Editor): string | null => {
  const selNode = createSelectionState(editor.state.selection);
  const ff = getNodeAttributes(editor.state, selNode.selectedNode?.node.type.name ?? '');
  return ff['lineHeight'] !== '' && ff['lineHeight'] !== undefined ? ff['lineHeight'] : '1.0';
};

export const getTextAlign = (editor: Editor): string => {
  switch (true) {
    case editor.isActive({ textAlign: 'left' }):
      return 'left';
    case editor.isActive({ textAlign: 'right' }):
      return 'right';
    case editor.isActive({ textAlign: 'center' }):
      return 'center';
    case editor.isActive({ textAlign: 'justify' }):
      return 'justify';
  }
  return '';
};

const isIcon = (editor: Editor): boolean => {
  return editor?.isActive('iconWrapper');
};

export const isLinkText = (editor: Editor): boolean => {
  if (editor.isActive('link')) return true;
  if (isIcon(editor)) {
    const attr = editor.getAttributes('link');
    return Object.keys(attr).length !== 0;
  }
  return false;
};

const getLinkAttributes = (editor: Editor) => {
  return editor.getAttributes('link');
};

export const getTextColor = (editor: Editor): string => {
  if (isLinkText(editor) && !isIcon(editor)) {
    const attr = getLinkAttributes(editor);
    return attr['color'];
  }
  const ff = editor.getAttributes('textStyle');
  if (ff['color']) return ff['color'];
  const fontColor = getFontStyleData('', 'color', editor);
  return fontColor;
};

export const extractFontFamily = (styles: string) => {
  const match = styles.match(/font-family:\s*([^;]+)/i);
  return match ? match[1].trim() : '';
};

const getCurrentLinkFontName = (editor: Editor): string => {
  const attr = getLinkAttributes(editor);
  if (attr.fontFamily) {
    return attr.fontFamily;
  }
  const linkFont = extractFontFamily(attr?.styles ?? '');
  return linkFont || '';
};

export const getFontName = (editor: Editor): string => {
  const ff = editor.getAttributes('textStyle');
  if (ff['fontFamily']) return ff['fontFamily'];
  if (isLinkText(editor)) {
    return getCurrentLinkFontName(editor);
  }
  const fontFamily = getFontStyleData('', 'font-family', editor);
  const fontFamilyList = fontFamily?.replace('"', '').replace('"', '').split(',');
  return fontFamilyList?.length === 1
    ? fontFamily?.replace('"', '').replace('"', '')
    : 'Source sans pro';
};

export function getNodeAttributes(state: EditorState, typeName: string): Attrs {
  const { from, to } = state.selection;
  const nodes: ProseMirrorNode[] = [];
  state.doc.nodesBetween(from, to, (node, pos, _parent) => {
    if (nodes.length) {
      return false;
    }
    if (node.type.name === typeName) {
      nodes.push(node);
    }
    return true;
  });
  if (nodes.length < 1) {
    return {};
  }
  return { ...nodes[0].attrs };
}

export const isBulletList = (editor: Editor): boolean => {
  return editor.isActive('bulletList');
};
export const isOrderedList = (editor: Editor): boolean => {
  return editor.isActive('orderedList');
};

export const isWholeContentSelected = (editor: Editor): EntireTextSelection => {
  const { $from, $to } = editor.state.selection;
  if ($from.sameParent($to) && ['heading', 'paragraph'].includes($from.parent.type.name)) {
    const nodeContent = $from.parent.textContent;
    const { ranges } = editor.state.selection;
    const from = Math.min(...ranges.map((range: { $from: { pos: number } }) => range.$from.pos));
    const to = Math.max(...ranges.map((range: { $to: { pos: number } }) => range.$to.pos));
    const selectedContent = editor.state.doc.textBetween(from, to);
    return { status: nodeContent === selectedContent, type: $from.parent.type.name };
  }
  return { status: false, type: '' };
};

export const applyStylesToList = (style: Attrs, attr: string, editor: Editor) => {
  editor
    .chain()
    .focus()
    .updateNodeStylesInRange(['listItem', 'paragraph', 'text', 'heading'], style, attr)
    .run();
  return;
};

export function parseFontSize(size: string): FontSizeData | null {
  const match = size.match(/(\d+(\.\d+)?)\s*([a-zA-Z%]+)?/);

  if (!match || match['input'] === undefined) return null;
  if (match['input'][0] === null || match['input'][0] === '-') {
    return null;
  }

  const fontSize: FontSizeData = {
    size: parseFloat(match[1]),
    unit: (match[3] || 'px').toLowerCase(),
  };
  return fontSize;
}

export function formatFontSize(size: FontSizeData | null | undefined): string {
  if (!size) {
    return '';
  }
  return `${size.size}${size.unit}`;
}

export function setDefaultFontStyle(defaultStyle: CustomHeaderStyleType[], element: Level | 0) {
  let style;
  switch (element) {
    case 1:
      style = defaultStyle[0].h1;
      break;
    case 2:
      style = defaultStyle[1].h2;
      break;
    case 3:
      style = defaultStyle[2].h3;
      break;
    case 4:
      style = defaultStyle[3].h4;
      break;
    case 5:
      style = defaultStyle[4].h5;
      break;
    case 6:
      style = defaultStyle[5].h6;
      break;
    default:
      style = defaultStyle[6].paragraph;
  }
  return style;
}

export function getHeadingLevel(editor: Editor): Level | 0 {
  const levels: Level[] = [1, 2, 3, 4, 5, 6];
  let returnLevel: Level | 0 = 0;
  levels.map((level: Level) => {
    if (editor.isActive('heading', { level: level })) {
      returnLevel = level;
    }
  });
  return returnLevel;
}

export const setFontWithMetrics = (
  editor: Editor,
  size: FontSizeData,
  isTableCell?: boolean,
): void => {
  if (size === null) {
    editor.chain().focus().unsetFontSize().run();
    return;
  }
  if (
    isTableCell ??
    ((isBulletList(editor) || isOrderedList(editor)) &&
      (isWholeContentSelected(editor)?.status ||
        editor.state.selection.$anchor.parent !== editor.state.selection.$head.parent))
  ) {
    applyStylesToList({ fontSize: formatFontSize(size) }, 'fontSize', editor);
    return;
  }
  if (isWholeContentSelected(editor).status) {
    editor.chain().focus().unsetFontSize().run();
    editor
      .chain()
      .updateNodeStyles(isWholeContentSelected(editor).type, {
        'font-size': `${size.size}${size.unit}`,
      })
      .run();
    return;
  }
  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  editor.chain().focus().setFontSize(size).run();
};

export function getBackgroundColor(editor: Editor): string {
  const ff = editor.getAttributes('textStyle');
  return !ff['backgroundColor'] || ff['backgroundColor'] === '' ? '#FFFFFF' : ff['backgroundColor'];
}

export function setTextColor(color: string, editor: Editor): void {
  if (
    (isBulletList(editor) || isOrderedList(editor)) &&
    (isWholeContentSelected(editor).status ||
      editor.state.selection.$anchor.parent !== editor.state.selection.$head.parent)
  ) {
    applyStylesToList({ color: color !== '' ? color : '#212529' }, 'color', editor);
    return;
  }

  if (color === '') {
    editor.chain().unsetColor().run();
    return;
  }
  if (editor?.getAttributes('link')) {
    editor.chain().setLinkColor(color).run();
    editor.chain().setColor(color).run();
    return;
  }
  editor.chain().setColor(color).run();
}

export function setBackgroundColor(color: string, editor: Editor): void {
  if (color === '') {
    editor.chain().unsetBackgroundColor().run();
    return;
  }
  editor.chain().setBackgroundColor(color).run();
}

export function getCurrentLink(editor: Editor): { url: string; newWindow: boolean } {
  if (!isLinkText(editor)) {
    return { url: '', newWindow: true };
  }
  const attr = editor.getAttributes('link');
  return { url: attr['href'], newWindow: attr['target'] === '_blank' };
}

export function getBorderColor(editor: Editor): string | null {
  const ff = editor.getAttributes('textStyle');
  return ff['borderColor'] ?? null;
}

export function getBorderSize(editor: Editor): string | null {
  const ff = editor.getAttributes('textStyle');
  return ff['borderWidth'] ?? null;
}

export function getBorderStyle(editor: Editor): string | null {
  const ff = editor.getAttributes('textStyle');
  return ff['borderStyle'] ?? null;
}

export function setBorderSize(editor: Editor, value: string | null): void {
  editor
    .chain()
    .setBorderWidth(value ? value + 'px' : '0px')
    .run();
  setBorderStyle(editor, getBorderStyle(editor));
  setBorderColor(editor, getBorderColor(editor) ?? '#000000');
}

export function setBorderColor(editor: Editor, value: string | null): void {
  if (value === '') {
    editor.chain().setBorderColor('#000000').setBorderWidth('0').setBorderStyle('solid').run();
    return;
  }
  editor
    .chain()
    .setBorderColor(value ?? '')
    .run();
}

export function setBorderStyle(editor: Editor, value: string | null): void {
  editor
    .chain()
    .setBorderStyle(value ?? 'solid')
    .run();
}
export const structuredParseHtml = (content: string) => {
  return { tag: content };
};
export function removeLink(editor: Editor): void {
  editor.chain().unsetLink().run();
}

export const applyLink = (
  editor: Editor,
  url: string,
  isNewTab: boolean,
  styles?: string,
): void => {
  if (isLinkText(editor)) {
    showLinkConfirmation(editor, url, isNewTab, styles);
  } else {
    applyLinkToElement(editor, url, isNewTab, styles);
  }
};

const showLinkConfirmation = (
  editor: Editor,
  url: string,
  newWindow: boolean,
  styles?: string,
): void => {
  hideLinkInfoPopup();

  const container = document.createElement('div');
  document.body.appendChild(container);

  const root = ReactDOM.createRoot(container);

  const closeModal = () => {
    root.unmount();
    document.body.removeChild(container);
  };

  const handleConfirm = () => {
    applyLinkToElement(editor, url, newWindow, styles);
    closeModal();
  };

  root.render(<LinkConfirmationModal show={true} onClose={closeModal} onConfirm={handleConfirm} />);
};

const applyLinkToElement = (
  editor: Editor,
  url: string,
  isNewTab: boolean,
  styles?: string,
): void => {
  const nodeType = (editor.state.selection as NodeSelection).node?.type.name;
  if (nodeType === 'image') {
    editor.commands.applyLinkToNode(url, isNewTab);
  } else {
    applyLinkToText(editor, url, isNewTab, styles);
  }
};

const applyLinkToText = (editor: Editor, url: string, isNewTab: boolean, styles?: string) => {
  let { from, to } = editor.state.selection;
  const linkParams = { href: url, target: isNewTab ? '_blank' : '_self', styles: styles ?? '' };
  if (from === to) {
    editor.commands.applyLinkToText(linkParams);
  } else {
    editor.chain().focus().setLink(linkParams).run();
  }
};

export const modifyAltText = (altText: string, decorativeText: boolean, editor: Editor) => {
  editor
    .chain()
    .focus()
    .setImageProperty({
      alt: decorativeText ? '' : altText,
      role: decorativeText ? 'presentation' : '',
    })
    .run();
};

export const getAltText = (editor: Editor): string | null => {
  const attr = getNodeAttributes(editor.state, 'image') as Record<string, string>;
  return attr['alt'] ?? null;
};

export const hasDecorationText = (editor: Editor): boolean => {
  const attr = getNodeAttributes(editor.state, 'image') as Record<string, string>;
  return !!attr['role'];
};

export const keepFirstDuplicateCssProperty = (style: string): string => {
  const styleMap: Record<string, string> = {};
  style?.split(';')?.forEach((rule) => {
    const [key, value] = rule?.split(':').map((s) => s.trim());
    if (key && !(key in styleMap)) {
      styleMap[key] = value;
    }
  });
  return Object.entries(styleMap)
    .map(([key, value]) => `${key}: ${value}`)
    .join('; ');
};

export function hasUnderlineInAnchor(editor: Editor): boolean {
  if (!editor.isActive('link')) return false;
  const linkAttrs = editor.getAttributes('link');
  const style = linkAttrs?.styles || '';
  return style.toLowerCase().includes('text-decoration: underline');
}

export function setCssProperty(styles: string, property: string, value: string): string {
  styles = styles.replace(new RegExp(`${property}:[^;]+;?`, 'gi'), '').trim();
  return styles + ` ${property}: ${value};`;
}
