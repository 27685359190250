import { EditorState, Transaction } from 'prosemirror-state';
import { Attrs } from 'prosemirror-model';

export function applyLinkToText(attrs: Attrs) {
  return (state: EditorState, dispatch?: (tr: Transaction) => void) => {
    const tr = state.tr;
    let textNode = state.schema.text('link');
    const selectedEndPosition = state.selection.from;
    // create a link mark
    let linkMark = state.schema.marks['link'].create({
      ...attrs,
      style: attrs.style ?? '',
    });
    // apply the link mark to the text node
    let markedNode = textNode.mark([linkMark]);

    tr.insert(selectedEndPosition, markedNode);
    if (dispatch) {
      dispatch(tr);
    }
    return true;
  };
}
