import { Editor } from '@tiptap/core';
import { Fragment, Node as ProsemirrorNode, Schema, NodeType, Attrs } from 'prosemirror-model';
import { CellSelection } from './cellSelection';
import { LoreeInteractiveEditorDashboardContentType } from '../../editorUtilityFunctions/lintEditorType';
import { getCustomFontFamilyStyles } from '../../editorUtilityFunctions/utilityFunctions';

export function isCellSelection(value: unknown): value is CellSelection {
  return value instanceof CellSelection;
}

export function createTable(
  schema: Schema,
  rowsCount: number,
  colsCount: number,
  withHeaderRow: boolean,
  withHeaderColumn: boolean,
  captionValue: string,
  editor: Editor,
  editorConfig?: LoreeInteractiveEditorDashboardContentType,
): ProsemirrorNode {
  const types = getTableNodeTypes(schema);
  const headerCells = [];
  const cells = [];
  const headerCellData = [];

  let paragraphFontStyles = '';
  let headingFontStyles = '';

  if (editorConfig?.customHeaderStyleList.length) {
    paragraphFontStyles = getCustomFontFamilyStyles(editorConfig, 0);
    headingFontStyles = getCustomFontFamilyStyles(editorConfig, 3);
  }

  const captionContent = Fragment.from(
    editor.schema.node(
      'paragraph',
      {
        className: 'lint-table-caption',
        style: 'padding: 5px; margin: 0px;' + paragraphFontStyles,
      },
      editor.schema.text(captionValue),
    ),
  );

  const captionData = createCell(types['caption'], captionContent, {
    colspan: 1,
    rowspan: 1,
    style: 'padding: 0px; margin: 0px; color: #6c757d; text-align: left;',
  });

  const paragraphContent = Fragment.from(
    editor.schema.node(
      'paragraph',
      {
        style:
          'padding: 5px; margin: 0;border-width: 0; border-style: solid; border-color: #000000; color: #212529;' +
          paragraphFontStyles,
        className: 'lint-tables',
      },
      editor.schema.text('Insert text here'),
    ),
  );

  const headerContent = Fragment.from(
    editor.schema.node(
      'heading',
      {
        style:
          'padding: 5px; margin: 0px;border-width: 0; border-style: solid; border-color: #000000; color: #212529;' +
          headingFontStyles,
        level: 3,
        className: 'lint-tables',
      },
      editor.schema.text('Header'),
    ),
  );

  for (let index = 0; index < colsCount; index += 1) {
    if (withHeaderColumn && index === 0) {
      const headerCell = createCell(types['header_cell'], headerContent, {
        scope: 'row',
        colspan: 1,
        rowspan: 1,
        style: 'background-color: #f8f9fb; border: 1px solid #000000; padding: 5px;',
      });

      if (headerCell) {
        headerCellData.push(headerCell);
      }
    } else {
      const cell = createCell(types['cell'], paragraphContent, {
        scope: 'none',
        colspan: 1,
        rowspan: 1,
        style: 'background-color: transparent; border: 1px solid #000000; padding: 5px;',
      });

      if (cell) {
        headerCellData.push(cell);
      }
    }
    const cell = createCell(types['cell'], paragraphContent, {
      scope: 'none',
      colspan: 1,
      rowspan: 1,
      style: 'background-color: transparent; border: 1px solid #000000; padding: 5px;',
    });

    if (cell) {
      cells.push(cell);
    }

    if (withHeaderRow) {
      const headerCell = createCell(types['header_cell'], headerContent, {
        scope: 'col',
        colspan: 1,
        rowspan: 1,
        style: 'background-color: transparent; border: 1px solid #000000; padding: 5px;',
      });

      if (headerCell) {
        headerCells.push(headerCell);
      }
    }
  }

  const rows = [];

  rows.push(captionData as ProsemirrorNode);

  for (let index = 0; index < rowsCount; index += 1) {
    if (withHeaderColumn && withHeaderRow) {
      rows.push(types['row'].createChecked(null, index === 0 ? headerCells : headerCellData));
    } else if (withHeaderRow) {
      rows.push(types['row'].createChecked(null, index === 0 ? headerCells : cells));
    } else if (withHeaderColumn) {
      rows.push(types['row'].createChecked(null, headerCellData));
    }
  }

  // default table styles.
  return types['table'].createChecked(
    {
      style:
        'width: 100%; text-align: left; margin: auto auto auto 0px; border: 1px solid #000000; border-collapse: collapse; background-color: #ffffff;',
    },
    rows,
  );
}

export function createCell(
  cellType: NodeType,
  cellContent?: Fragment | ProsemirrorNode | Array<ProsemirrorNode>,
  attr: Attrs = {},
): ProsemirrorNode | null | undefined {
  if (cellContent) {
    return cellType.createChecked(attr, cellContent);
  }

  return cellType.createAndFill(attr);
}

export function getTableNodeTypes(schema: Schema): { [key: string]: NodeType } {
  if (schema.cached['tableNodeTypes']) {
    return schema.cached['tableNodeTypes'];
  }

  const roles: { [key: string]: NodeType } = {};

  Object.keys(schema.nodes).forEach((type) => {
    const nodeType = schema.nodes[type];

    if (nodeType.spec['tableRole']) {
      roles[nodeType.spec['tableRole']] = nodeType;
    }
  });

  schema.cached['tableNodeTypes'] = roles;

  return roles;
}
