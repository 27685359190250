/* eslint-disable react-hooks/exhaustive-deps */
import React, { ChangeEvent, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import './imageModal.scss';
import { ReactComponent as LinkIcon } from '../../../../assets/icons/linkIcon.svg';
import { LocalImageUploadComponent } from './localImageUpload';
import TabSectionComponent from '../../../utils/tabSection';

interface ImageModalBodyContentInterface {
  imageCollection: Array<{ id: number; name: string; url: string }>;
  handleSelectedElement: Function;
  handleConfirmState: Function;
  isError: boolean;
  setIsError: Function;
  setScreenType: Function;
  setLocalImageDetails: Function;
  isErrorInFetch: boolean;
  isFetching: boolean;
  componentType?: string;
}

const ImageModalBodyContent = (props: ImageModalBodyContentInterface) => {
  const {
    imageCollection,
    handleSelectedElement,
    handleConfirmState,
    isError,
    setIsError,
    setScreenType,
    setLocalImageDetails,
    isErrorInFetch,
    isFetching,
    componentType,
  } = props;

  const [rightContainerDisabled, setRightContainerDisabled] = useState(false);
  const [uploadImageDisabled, setUploadImageDisabled] = useState(false);
  const [externalLinkDisabled, setExternalLinkDisabled] = useState(false);
  const [externalLink, setExternalLink] = useState('');
  const [isSort, setIsSort] = useState<boolean>(true);
  const [isSearch, setIsSearch] = useState<boolean>(true);
  const [tabType, setTabType] = useState('Images');

  const sectionTypes = [
    {
      title: 'Images',
      disabled: false,
      elementCollection: imageCollection,
      hide: false,
    },
    {
      title: 'Icons',
      disabled: false,
      hide:
        componentType === 'imageslider' ||
        componentType === 'DragandDrop' ||
        componentType === 'Hotspot',
    },
    {
      title: 'Video',
      disabled: false,
      hide: componentType === 'DragandDrop' || componentType === 'Hotspot',
    },
  ];

  const handleExternalLink = (event: ChangeEvent<HTMLInputElement>) => {
    const selectedImage = event.target.value;
    if (selectedImage.trim() === '') {
      handleConfirmState(true);
    } else {
      handleConfirmState(false);
    }
    setExternalLink(selectedImage);
    setIsError(false);
    handleSelectedElement({
      url: selectedImage,
      fileName: '',
      elementType: 'externalLink',
      mediaType: 'image',
    });
  };

  const handleElement = (
    image?: { name: string; url: string },
    index?: number,
    event?: React.MouseEvent,
  ) => {
    event?.stopPropagation();
    removePreviousSelection();
    if (!image && !index) {
      handleSelectedElement({ elementType: '', url: '', fileName: '', name: '', mediaType: '' });
      handleConfirmState(true);
      return;
    }
    enableOrDisableContainers(false, false, false);
    const selectedImage = image?.url
      .substring(1, image?.url.length - 1)
      .split(' ')
      .join('%20');
    const selectedElement = document.getElementById(`${image?.name}_${index}`);
    selectedElement?.classList.add('selected-media');
    handleConfirmState(false);
    const selectionDetails = {
      url: selectedImage,
      fileName: image?.name,
      elementType: 'image',
      mediaType: 'image',
      id: `${image?.name}_${index}`,
    };
    handleSelectedElement(selectionDetails);
  };

  const handleSelectedIcon = (icon: { name: string }, index: number, event: React.MouseEvent) => {
    event.stopPropagation();
    document.getElementsByClassName('selected-icon')[0]?.classList.remove('selected-icon');
    (event.target as HTMLElement).classList.add('selected-icon');
    handleConfirmState(false);
    const selectionDetails = {
      name: icon.name,
      elementType: 'icon',
      mediaType: 'icon',
    };
    handleSelectedElement(selectionDetails);
  };

  const handleTabSelection = (type: string) => {
    switch (type) {
      case 'Icons':
        setIsSort(false);
        setTabType('Icons');
        setIsSearch(true);
        setUploadImageDisabled(true);
        setExternalLinkDisabled(true);
        enableOrDisableContainers(false, true, false);
        break;
      case 'Images':
        setIsSort(true);
        setTabType('Images');
        setIsSearch(true);
        setUploadImageDisabled(false);
        setExternalLinkDisabled(false);
        enableOrDisableContainers(false, false, false);
        break;
      case 'Video':
        setIsSort(false);
        setTabType('Video');
        setIsSearch(false);
        setUploadImageDisabled(true);
        setExternalLinkDisabled(true);
        enableOrDisableContainers(false, true, true);
        break;
    }
    handleSelectedElement({ elementType: '', url: '', fileName: '', name: '', mediaType: '' });
    handleConfirmState(true);
  };

  const removePreviousSelection = () => {
    document.getElementsByClassName('selected-media')[0]?.classList.remove('selected-media');
  };

  const enableOrDisableContainers = (
    isRightContainer: boolean,
    isUploadImage: boolean,
    isExternalLinks: boolean,
  ) => {
    setExternalLink('');
    setIsError(false);
    setRightContainerDisabled(isRightContainer);
    setUploadImageDisabled(isUploadImage);
    setExternalLinkDisabled(isExternalLinks);
  };
  const externalLinkOnFocus = (e: React.FocusEvent<HTMLInputElement, Element>) => {
    if (e.target.value.trim() === '') {
      handleConfirmState(true);
    }
    setExternalLinkDisabled(false);
    setRightContainerDisabled(true);
    setUploadImageDisabled(true);
  };
  return (
    <Row className='lint-media-modal mx-1' data-testid='lint-media-modal'>
      <Col
        lg={9}
        md={8}
        sm={7}
        className={`lint-image-modal-right-container ${rightContainerDisabled ? 'disabled' : null}`}
        tabIndex={0}
        role='button'
        onClick={() => {
          setRightContainerDisabled(false);
        }}
      >
        <TabSectionComponent
          sectionTypes={sectionTypes}
          handleElement={handleElement}
          isSearch={isSearch}
          isSort={isSort}
          isErrorInFetch={isErrorInFetch}
          isFetching={isFetching}
          handleSelectedIcon={handleSelectedIcon}
          handleTabSelection={handleTabSelection}
          handleSelectedElement={handleSelectedElement}
          handleConfirmState={handleConfirmState}
          enableOrDisableContainers={enableOrDisableContainers}
        />
      </Col>
      <Col
        lg={3}
        md={4}
        sm={5}
        className='lint-image-modal-body-content-right-container'
        style={{ padding: '16px', width: '25%' }}
      >
        <div
          className='right-container-layout d-flex flex-column w-100'
          id='image-modal-right-container-layout'
          style={{ width: '100%' }}
        >
          <LocalImageUploadComponent
            uploadImageDisabled={uploadImageDisabled}
            sectionTypeTitle={tabType}
            enableOrDisableContainers={enableOrDisableContainers}
            setScreenType={setScreenType}
            setLocalImageDetails={setLocalImageDetails}
            handleSelectedElement={handleSelectedElement}
            handleConfrimState={handleConfirmState}
          />
          <div id='image-modal-divider'>
            <div className='image-modal-divider-content'>
              <span className='image-modal-divider-text image-modal-icon'>or</span>
            </div>
          </div>
          <div
            id='image-modal-external-link-input'
            className={`${externalLinkDisabled ? 'disable-image-upload' : ''}`}
          >
            <span className='image-modal-icon-inputfield'>
              <LinkIcon />
            </span>
            <input
              id='lint-external-image-input'
              value={externalLink.replace(/ +/g, ' ')}
              type='text'
              className={`${isError ? 'invalid-url-state' : ''} ml-2`}
              placeholder='External Image Link'
              autoComplete='off'
              disabled={tabType === 'Video'}
              style={{
                cursor: tabType === 'Video' ? 'not-allowed' : 'pointer',
              }}
              onChange={(e) => handleExternalLink(e)}
              onFocus={(e) => externalLinkOnFocus(e)}
            />
            <div
              id='lint-external-image-error-message'
              className={`${isError ? '' : 'd-none'} text-danger`}
            >
              <p>Enter a valid Image url</p>
            </div>
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default ImageModalBodyContent;
