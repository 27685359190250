/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactNode, useEffect, useState } from 'react';
import { Button, Container, Row, Col, Form } from 'react-bootstrap';
import { ToastManager } from '@crystaldelta/loree-ui-components';
import { connect } from 'react-redux';
import { createContent, getContent, editContent, createGlobalContent } from '../../middleware/api';
import { initializeReactGA } from '../../middleware/googleAnalytics';
import Header from '../layouts/navbar';
import CircleLoader from '../loader/loader';
import { getModalDetails, getValueFromSession, setSharedAccountId } from '../../middleware/utils';
import { ReactComponent as Collapse1 } from '../../assets/icons/expandIcon.svg';
import { ReactComponent as Collapse2 } from '../../assets/icons/collapse.svg';
import ComponentBody from './componentBody';
import CustomizeComponent from './customizeLayout';
import {
  getInteractiveTitle,
  getLibraryId,
  setEditValue,
  getComponentCustomValues,
  removeEmptyTags,
  setContentArray,
} from './utils';
import PreviewComponent from './preview';
import store from '../../redux/store';
import { getValidationFunction, validateButtonForm } from './validate';
import { handleButtonSubmit, checkInteractiveTitle } from './button/utils';
import { mapDispatchToProps, mapStateToProps } from './formPropsData';
import ModalComponent from '../utils/modal';
import './form.scss';
import NoContentComponent from '../../204';
import { CustomFontsType } from '../editor/editorUtilityFunctions/lintEditorType';
import { getFeaturesList } from '../interactiveManagement/utils';
import { CONSTANTS } from '../editor/editorUtilityFunctions/constants';

interface ResponseType {
  error: any;
  id: string;
}
interface FailType {
  title?: string;
  fontSize?: string;
  btnFontSize?: string;
}
export function FormComponent(props: any) {
  const [opened, setOpened] = useState(true);
  const [alreadyExist, setAlreadyExist] = useState<string | null>(null);
  const [Modelshow, setModelshow] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [saveType, setSaveType] = useState('private');
  const [isEdit, setIsEdit] = useState(true);
  const [config, setConfig] = useState({});
  const [editContentData, setEditContentData] = useState({});
  const [doesNotExists, setDoesNotExtis] = useState(false);
  const [modalType, setModalType] = useState('');
  const [redirectUrl, setRedirectUrl] = useState({
    homeUrl: '',
    globalInteractiveUrl: '',
    myInteractiveUrl: '',
  });
  const { errors, title } = props;
  const { id, type } = props.match.params;
  const configCheck = props.location.state ? props.location.state.config : config;
  const [customHeaderStylesPropsList, setCustomHeaderStylesPropsList] = useState(
    props.location.state?.config.customHeaderStyleList ?? [],
  );
  const [customLinkStylesPropsList, setCustomLinkStylesPropsList] = useState(
    props.location.state?.config.customLinkStyleList ?? [],
  );
  const [fontFamilyPropsList, setFontFamilyPropsList] = useState(
    props.location.state?.config.fontFamilyList ?? [],
  );

  const getTitleValue = () => {
    switch (type) {
      case 'multiplechoice':
        return props.mcqTitle;
      default:
        return title;
    }
  };

  const getErrorValue = () => {
    switch (type) {
      case 'Button':
        return props.buttonError;
      case 'multiplechoice':
        return props.mcqError;
      default:
        return errors;
    }
  };
  const getTitleFunction = () => {
    const { HandleTitle, HandleFlipCardTitle, HandleMcqTitle } = props;
    switch (type) {
      case 'flipcard':
        return HandleFlipCardTitle;
      case 'multiplechoice':
        return HandleMcqTitle;
      default:
        return HandleTitle;
    }
  };
  const handleTitleChange = getTitleFunction();

  // toggle the collapse ID for changing the accordion icon
  const toggleBox = () => {
    setOpened(!opened);
  };
  const getEditFunction = () => {
    const { MakeEdit, MakeFlipCardEdit, MakeMcqEdit } = props;
    switch (type) {
      case 'flipcard':
        return MakeFlipCardEdit;
      case 'multiplechoice':
        return MakeMcqEdit;
      default:
        return MakeEdit;
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const {
          SetType,
          SetValue,
          SetMcqValue,
          dndLayout,
          ResetButtonStore,
          ResetFlipStore,
          ResetFormStore,
          ResetMcqStore,
          ResetComponentStore,
        } = props;
        const { type, id, user, orgId } = props.match.params;
        const { search, pathname } = props.location;
        const userId = getValueFromSession('user_id');
        const org_id = getValueFromSession('org_id');
        const userEmail = getValueFromSession('user');
        const apiParams = userId ?? user;
        setSharedAccountId(search);
        initializeReactGA(pathname);
        SetType({
          type: type,
        });
        ResetButtonStore();
        ResetFlipStore();
        ResetFormStore();
        ResetMcqStore();
        ResetComponentStore();
        if (!userId && !id) {
          window.location.href = '/401';
        } else if (id) {
          if (user && !userId) {
            sessionStorage.setItem('user_id', user);
          }

          if (orgId && org_id !== '' && orgId !== org_id) {
            sessionStorage.setItem('org_id', orgId);
          }

          const data = await getContent(id, apiParams);
          let config = data.config ? JSON.parse(data.config) : {};
          config = getFeaturesList(config, '');
          setConfig(config);

          const configsList = JSON.parse(data.config)?.configsList;

          if (data.info) {
            setDoesNotExtis(true);
            if (!userEmail) sessionStorage.setItem('user', data.email);
            return;
          }

          setEditContentData(data);
          if (!userEmail) sessionStorage.setItem('user', data.userEmail);

          if (data.message === 'Unauthorized User or Invalid Content') {
            return props.history.push('/401');
          }

          //For cancel work flow redirect url
          const homeUrl = `/interactives/${data.userEmail}/${orgId}/${user}`;
          const myinteractiveUrl = `/Contents/${data.userEmail}/${user}`;
          const globalInteractiveUrl = `/global/${orgId}`;
          setRedirectUrl({
            homeUrl: homeUrl,
            myInteractiveUrl: myinteractiveUrl,
            globalInteractiveUrl: globalInteractiveUrl,
          });
          if (type === 'Button') {
            setIsEdit(!isEdit);
            return;
          } else {
            let parameters = JSON.parse(data.parameters);
            setAlreadyExist(
              pathname.includes('duplicate')
                ? '* DUPLICATED INTERACTIVE - Please enter a new title'
                : null,
            );
            setEditValue(
              type,
              data,
              setContentArray(
                parameters,
                type,
                type === 'multiplechice' ? SetMcqValue : SetValue,
                dndLayout,
              ),
              parameters,
              pathname,
              getEditFunction(),
            );
          }

          let fontFamilyConfigList = props.location.state?.config.fontFamilyList ?? [];

          let fontFamilyList;
          let customHeaderStyleList;
          if (props.history.location.state) {
            fontFamilyList = props.history.location.state.fontFamilyList;
            customHeaderStyleList = props.history.location.state.customHeaderStyleList;
          } else {
            fontFamilyList = configsList?.customFonts;
            customHeaderStyleList = configsList?.customHeaderStyle;
          }
          if (fontFamilyConfigList.length === 0) {
            fontFamilyConfigList = fontFamilyList?.fonts ?? [];
          }

          let customFontFaceList: string[] = [];
          let fontApiUrl = CONSTANTS.LOREE_GOOGLE_FONT_API_URL;

          fontFamilyConfigList.forEach((font: CustomFontsType) => {
            if (
              Object.prototype.hasOwnProperty.call(font, 'url') &&
              font.url &&
              !Object.hasOwn(font, 'customFont')
            ) {
              const api = font.url.split('=');
              fontApiUrl += `&family=${api[1]}`;
            }
            if (Object.hasOwn(font, 'customFont')) {
              customFontFaceList.push(font.customFont as string);
            }
          });

          const linkTag = document.createElement('link');
          linkTag.setAttribute('rel', 'stylesheet');
          linkTag.setAttribute('href', fontApiUrl);
          linkTag.setAttribute('id', 'customFontsLink');

          const headElement = document.head;

          const existingCustomFontsLink = headElement.getElementsByClassName('customFontsLink')[0];
          existingCustomFontsLink?.remove();
          const existingCustomFonts = headElement.getElementsByClassName('customFonts')[0];
          existingCustomFonts?.remove();

          headElement?.appendChild(linkTag);

          if (customFontFaceList.length > 0) {
            const styleTag = document.createElement('style');
            customFontFaceList.forEach((customFontFace) => {
              styleTag.append(customFontFace);
            });
            headElement?.appendChild(styleTag);
          }
          if (
            customHeaderStylesPropsList.length === 0 &&
            fontFamilyPropsList.length === 0 &&
            customLinkStylesPropsList.length === 0 &&
            fontFamilyList?.fonts &&
            customHeaderStyleList?.customHeader &&
            customLinkStylesPropsList.customLink
          ) {
            setFontFamilyPropsList(fontFamilyList?.fonts ?? []);
            setCustomHeaderStylesPropsList(customHeaderStyleList?.customHeader ?? []);
            setCustomLinkStylesPropsList(customLinkStylesPropsList.customLink ?? []);
          }
        } else {
          let fontFamilyConfigList = props.location.state?.config.fontFamilyList ?? [];

          let fontFamilyList;
          let customHeaderStyleList;
          if (props.history.location.state) {
            fontFamilyList = props.history.location.state.fontFamilyList;
            customHeaderStyleList = props.history.location.state.customHeaderStyleList;
          } else {
            fontFamilyList = configCheck?.fontFamilyList;
            customHeaderStyleList = configCheck?.customHeaderStyleList;
          }
          if (fontFamilyConfigList.length === 0) {
            fontFamilyConfigList = fontFamilyList ?? [];
          }

          let customFontFaceList: string[] = [];
          let fontApiUrl = CONSTANTS.LOREE_GOOGLE_FONT_API_URL;

          fontFamilyConfigList.forEach((font: CustomFontsType) => {
            if (
              Object.prototype.hasOwnProperty.call(font, 'url') &&
              font.url &&
              !Object.hasOwn(font, 'customFont')
            ) {
              const api = font.url.split('=');
              fontApiUrl += `&family=${api[1]}`;
            }
            if (Object.hasOwn(font, 'customFont')) {
              customFontFaceList.push(font.customFont as string);
            }
          });

          const linkTag = document.createElement('link');
          linkTag.setAttribute('rel', 'stylesheet');
          linkTag.setAttribute('href', fontApiUrl);
          linkTag.setAttribute('id', 'customFontsLink');

          const headElement = document.head;

          const existingCustomFontsLink = headElement.getElementsByClassName('customFontsLink')[0];
          existingCustomFontsLink?.remove();
          const existingCustomFonts = headElement.getElementsByClassName('customFonts')[0];
          existingCustomFonts?.remove();

          headElement?.appendChild(linkTag);

          if (customFontFaceList.length > 0) {
            const styleTag = document.createElement('style');
            customFontFaceList.forEach((customFontFace) => {
              styleTag.append(customFontFace);
            });
            headElement?.appendChild(styleTag);
          }
          if (
            customHeaderStylesPropsList.length === 0 &&
            fontFamilyPropsList.length === 0 &&
            customLinkStylesPropsList.length === 0 &&
            fontFamilyList?.fonts &&
            customHeaderStyleList?.customHeader &&
            customLinkStylesPropsList?.customLink
          ) {
            setFontFamilyPropsList(fontFamilyList?.fonts ?? []);
            setCustomHeaderStylesPropsList(customHeaderStyleList?.customHeader ?? []);
            setCustomLinkStylesPropsList(customLinkStylesPropsList?.customLink ?? []);
          }
        }
        setIsEdit(!isEdit);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type]);

  //value from title within the panel
  const handleChange = (i: any, event: React.ChangeEvent) => {
    const { errors, parameters, HandleChange, type } = props;
    const { value } = event.target as HTMLInputElement;
    let fail = errors;
    let length = type === 'DragandDrop' ? 100 : 250;
    if (value.length < length) {
      let content = [...parameters];
      content[i] = { ...content[i], title: !value.trim() ? '' : value };
      HandleChange({
        parameters: content,
        errors: fail,
      });
    }
  };

  // value from main-title
  const handleTitle = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setAlreadyExist(null);
    if (props.location.pathname.includes('/duplicate')) {
      setAlreadyExist(value === '' ? '* DUPLICATED INTERACTIVE - Please enter a new title' : null);
    }
    if (value.length < 256) {
      handleTitleChange({
        title: value.replace(/ +/g, ' '),
      });
    }
  };

  //sumbit the form values to database and send response to preview page
  const handleSubmit = async (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    isGlobal: boolean,
  ) => {
    const { title, ErrorsFetch, dndLayout } = props;
    let { id, type } = props.match.params;
    let response: ResponseType = {
      id: '',
      error: {},
    };
    let user_id: string = '';
    const typeid = getLibraryId(type);
    removeEmptyTags(getParameters(), type);
    if (props.match.params.user === undefined || props.match.params.user === '') {
      user_id = sessionStorage.getItem('user_id') as string;
    } else {
      user_id = props.match.params.user;
    }
    event.preventDefault();
    const validationFunction = getValidationFunction(type, dndLayout);
    let fail: FailType = validationFunction(props, ErrorsFetch, props.location.pathname, type);
    let dupicatetitle =
      fail.title === '* DUPLICATED INTERACTIVE - Please enter a new title'
        ? delete fail['title']
        : null;
    if (Object.keys(fail).length === 0 && alreadyExist === null) {
      const titleValue = title.trim();
      let data = {
        user_id: user_id,
        library_id: typeid,
        id,
        organization_id: sessionStorage.getItem('org_id'),
        title: titleValue,
        parameters: JSON.stringify(getParameters()),
        customisations: {
          ...getComponentCustomValues(type, props),
          fontsList: JSON.stringify(fontFamilyPropsList),
        },
        active: true,
        private: !isGlobal && true,
        global: isGlobal && true,
        editable: isGlobal && true,
      };
      if (id) {
        if (props.location.pathname.includes('/duplicate') && dupicatetitle === null) {
          response = await createContent(data);
          if (response.error) {
            setAlreadyExist('Title already exist, Please enter a new title');
            return;
          }
          setShowToast(true);
          setToastMessage('Content duplicated successfully');
        } else {
          response.id = id;
          const updateResponse = await editContent({
            id,
            body: data,
          });
          if (updateResponse.error) {
            setAlreadyExist('Title already exist, Please enter a new title');
            return;
          }
          setShowToast(true);
          setToastMessage(
            'Content updated successfully. Page is redirect to the view of the updated element',
          );
        }
      } else if (type) {
        response = await createContent(data);
        if (isGlobal) {
          const globalData = {
            organization: sessionStorage.getItem('org_id'),
            title: titleValue,
            interactiveIds: response.id,
            accessType: 'global',
            accountIds: '',
            editable: 'write',
          };
          await createGlobalContent(globalData);
        }
        if (response.error) {
          setAlreadyExist('Title already exist, Please enter a new title');
          return;
        }
      }
      setTimeout(() => {
        props.history.push({
          pathname: `/contentview/${type}/${user_id}/${response.id}`,
          state: {
            user: props.location.state !== undefined ? props.location.state.user : '',
            user_id: props.location.state !== undefined ? props.location.state.user_id : '',
            config: props.location.state ? props.location.state.config : config,
          },
        });
      }, 2000);
    }
  };

  const modalToggle = (event: { preventDefault: () => void }) => {
    if (type === 'Button') {
      const buttonStateCollection = store.getState().button;
      const error = validateButtonForm(buttonStateCollection, props.SetButtonFormError);
      if (Object.keys(error).length === 0) {
        setModelshow(true);
      }
      return;
    }
    const { title, ErrorsFetch, dndLayout } = props;
    event.preventDefault();
    removeEmptyTags(getParameters(), type);
    const validationFunction = getValidationFunction(type, dndLayout);
    let fail = validationFunction(props, ErrorsFetch, props.location.pathname, type);
    if (title.trim() === '') {
      return;
    }
    if (Object.keys(fail).length === 0) {
      setModelshow(true);
    }
  };

  const Modelclose = () => {
    setModelshow(false);
  };

  const getPreviewProps = () => {
    switch (type) {
      case 'Button':
        return store.getState().button;
      case 'flipcard':
        return store.getState().flipform;
      case 'multiplechoice':
        return store.getState().mcqform;
      default:
        return props;
    }
  };

  const getAddPannelFunction = () => {
    switch (type) {
      case 'flipcard':
        return props.addFlipCardPanels;
      case 'multiplechoice':
        return props.addMcqPannel;
      case 'DragandDrop':
        return props.dndLayout === 'CustomOverlay' ? props.AddDndSection : props.addPanels;
      case 'Hotspot':
        return props.AddDndSection;
      default:
        return props.addPanels;
    }
  };

  const getParameters = () => {
    switch (type) {
      case 'flipcard':
        return props.flipCardParameters;
      case 'multiplechoice':
        return props.mcqParameters;
      case 'DragandDrop':
        return props.dndLayout === 'CustomOverlay' ? props.dndParameters : props.parameters;
      case 'Hotspot':
        return props.dndParameters;
      default:
        return props.parameters;
    }
  };

  const getContentArray = () => {
    switch (type) {
      case 'flipcard':
        return props.flipCardContentArr;
      case 'multiplechoice':
        return props.mcqContentArr;
      case 'DragandDrop':
        return props.dndLayout === 'CustomOverlay' ? props.dndArray : props.contentArr;
      case 'Hotspot':
        return props.dndArray;
      default:
        return props.contentArr;
    }
  };
  const createInteractiveModal = () => {
    return {
      title: 'Create Interactive',
      body: (
        <div style={{ fontSize: '16px' }}>
          <p className='mb-2'>Create your interactive as: </p>
          <Form.Check
            label='My Interactive'
            name='createInteractiveButton'
            type='radio'
            value='private'
            id='private-button'
            onChange={() => setSaveType('private')}
            checked={saveType === 'private'}
            className='mb-2 icon-label createInteractiveButton'
          />
          <Form.Check
            label='Global Interactive'
            name='createInteractiveButton'
            type='radio'
            value='global'
            id='global-button'
            onChange={() => setSaveType('global')}
            checked={saveType === 'global'}
            className='icon-label createInteractiveButton'
          />
        </div>
      ) as ReactNode,
    };
  };

  const handletriggerCreateModal = async () => {
    const { ErrorsFetch, dndLayout, title } = props;
    let fail: FailType = {};
    if (type !== 'Button') {
      const validationFunction = getValidationFunction(type, dndLayout);
      fail = validationFunction(props, ErrorsFetch, props.location.pathname, type);
    } else {
      const { SetButtonFormError } = props;
      const buttonStateCollection = store.getState().button;
      const failButton = validateButtonForm(buttonStateCollection, SetButtonFormError);
      if (failButton.title || failButton.name || failButton.link) {
        return;
      }
      const showButtonNextStep = await checkInteractiveTitle(buttonStateCollection.title);
      if (showButtonNextStep) {
        setShowModal(true);
      } else {
        SetButtonFormError({
          errors: { title: 'Title already exist, Please enter a new title' },
        });
      }
      return;
    }
    if (Object.keys(fail).length === 0) {
      const showNextStep = await checkInteractiveTitle(title);
      if (showNextStep) {
        setShowModal(true);
      } else {
        setAlreadyExist('Title already exist, Please enter a new title');
      }
    }
  };

  const handleButtonEvent = async (id?: string) => {
    const response: { message: string; id: string } = await handleButtonSubmit(
      props,
      false,
      props.location.state ? props.location.state.config : config,
      id,
    );
    const userEmail = getValueFromSession('user');
    handleButtonEventToast(response);
    setTimeout(() => {
      props.history.push({
        pathname: `/contentview/button/${userEmail}/${id ?? response.id}`,
        state: {
          user: userEmail,
          user_id: getValueFromSession('user_id'),
          config: props.location.state ? props.location.state.config : config,
        },
      });
    }, 2000);
  };

  const handleCancel = () => {
    if (props.location.state) {
      history.back();
    } else {
      props.history.push(
        configCheck.myinteractivecreate || configCheck.globalinteractivecreate
          ? redirectUrl.homeUrl
          : configCheck.myinteractive
          ? redirectUrl.myInteractiveUrl
          : configCheck.globalinteractive
          ? redirectUrl.globalInteractiveUrl
          : '/shared',
      );
    }
  };
  const handleButtonEventToast = (response: { message: string }) => {
    switch (true) {
      case response.message === 'Content Updated':
        setShowToast(true);
        setToastMessage(
          'Content updated successfully. Page is redirect to the view of the updated element',
        );
        break;
      case props.location.pathname.includes('/duplicate'):
        setShowToast(true);
        setToastMessage(
          'Content duplicated successfully. Page is redirect to the view of the updated element',
        );
        break;
    }
  };
  return (
    <>
      {doesNotExists && <NoContentComponent />}
      {id && isEdit && !doesNotExists && <CircleLoader loaderType={''} />}
      {!doesNotExists && !isEdit && (
        <>
          <Container fluid className='margin' id='interactiveMargin'>
            <div className='d-flex flex-wrap'>
              {opened && (
                <CustomizeComponent
                  {...props}
                  elementType={type}
                  fontFamilyPropsList={fontFamilyPropsList}
                  customHeaderStylesPropsList={customHeaderStylesPropsList}
                  customLinkStylesPropList={customLinkStylesPropsList}
                />
              )}
              <div className={`${opened ? 'form-wrapper' : 'w-100'}`}>
                <div
                  className='customize-toggle my-3'
                  onClick={toggleBox}
                  tabIndex={0}
                  onKeyDown={toggleBox}
                  role='button'
                >
                  {!opened ? <Collapse1 /> : <Collapse2 />}
                </div>
                <Row className='component-body mr-2'>
                  <div className='d-flex flex-row p-0 justify-content-between accordion-nav'>
                    <Col
                      className='text-capitalize h4 fw-bolder'
                      style={{
                        marginBottom: '10px',
                        padding: '10px',
                      }}
                    >
                      <span
                        className='title-text'
                        style={{
                          color: '#0B099C',
                          fontFamily: 'Lexend',
                          fontWeight: 400,
                          fontSize: '18px',
                        }}
                      >
                        {getInteractiveTitle(type)}
                      </span>
                    </Col>
                    <Button className='preview-button' onClick={modalToggle} size='sm'>
                      Preview
                    </Button>
                    {Modelshow && (
                      <PreviewComponent
                        content={getPreviewProps()}
                        show={Modelshow}
                        onHide={Modelclose}
                        type={type}
                      />
                    )}
                  </div>
                </Row>
                <ComponentBody
                  id={id}
                  editContentData={editContentData}
                  handleTitleChange={(e) => handleTitle(e)}
                  alreadyExist={alreadyExist}
                  titleValue={getTitleValue()}
                  errors={getErrorValue()}
                  contentArr={getContentArray()}
                  addPanelHandel={getAddPannelFunction()}
                  parameter={getParameters()}
                  number={props.num}
                  type={props.match.params.type}
                  handleChange={props.HandleChange}
                  handleTitle={(i: any, event: any) => handleChange(i, event)}
                  pathName={props.location.pathname}
                  optionId={props.mcqOptionId}
                  dndLayout={props.dndLayout}
                  editorConfig={{
                    customHeaderStyleList: customHeaderStylesPropsList,
                    fontFamilyList: fontFamilyPropsList,
                    customLinkStyleList: customLinkStylesPropsList,
                  }}
                />
              </div>
            </div>
            {showToast && (
              <ToastManager
                toastType='success'
                toastMessage={toastMessage}
                closeToast={() => setShowToast(false)}
                closeButton
              />
            )}
          </Container>
          <div className='footer-button-wrapper pb-2'>
            <Button
              className='footer-cancel-btn mx-2'
              type='submit'
              id='global-lint'
              onClick={() => {
                setShowModal(true);
                setModalType('cancel');
              }}
            >
              Cancel
            </Button>
            {(configCheck.myinteractivecreate || configCheck.globalinteractivecreate) &&
              ((id && props.location.pathname.includes('/duplicate')) || !id) && (
                <Button
                  className='footer-create-btn mx-2'
                  id='global-lint'
                  type='submit'
                  onClick={(e) => {
                    setModalType('Create');
                    switch (type) {
                      case 'Button':
                        {
                          configCheck.myinteractivecreate && configCheck.globalinteractivecreate
                            ? void handletriggerCreateModal()
                            : handleButtonEvent();
                        }
                        break;
                      default:
                        {
                          configCheck.myinteractivecreate && configCheck.globalinteractivecreate
                            ? void handletriggerCreateModal()
                            : handleSubmit(e, configCheck.globalinteractivecreate);
                        }
                        break;
                    }
                  }}
                  disabled={
                    !configCheck.myinteractivecreate && !configCheck.globalinteractivecreate
                  }
                >
                  Create
                </Button>
              )}
            {(configCheck.myinteractiveedit ||
              configCheck.globalinteractiveedit ||
              configCheck.sharedinteractiveedit) &&
              id &&
              !props.location.pathname.includes('/duplicate') && (
                <Button
                  className='footer-create-btn mx-2'
                  id='global-lint'
                  type='submit'
                  onClick={(e) => {
                    switch (type) {
                      case 'Button':
                        handleButtonEvent(id);
                        break;
                      default:
                        handleSubmit(e, false);
                        break;
                    }
                  }}
                  disabled={!configCheck.myinteractiveedit && !configCheck.globalinteractiveedit}
                >
                  Update
                </Button>
              )}
            {!configCheck.myinteractivecreate && configCheck.globalinteractivecreate && (
              <p className='global-note-message m-0 mt-1'>
                Note: This Interactive will create as Global
              </p>
            )}
          </div>
        </>
      )}
      <div className='footer-button-wrapper pb-2'>
        <Button
          className='footer-cancel-btn mx-2'
          type='submit'
          id='global-lint'
          onClick={() => {
            setShowModal(true);
            setModalType('cancel');
          }}
        >
          Cancel
        </Button>
        {(configCheck.myinteractivecreate || configCheck.globalinteractivecreate) &&
          ((id && props.location.pathname.includes('/duplicate')) || !id) && (
            <Button
              className='footer-create-btn mx-2'
              id='global-lint'
              type='submit'
              onClick={(e) => {
                setModalType('Create');
                switch (type) {
                  case 'Button':
                    {
                      configCheck.myinteractivecreate && configCheck.globalinteractivecreate
                        ? handletriggerCreateModal()
                        : handleButtonEvent();
                    }
                    break;
                  default:
                    {
                      configCheck.myinteractivecreate && configCheck.globalinteractivecreate
                        ? handletriggerCreateModal()
                        : handleSubmit(e, configCheck.globalinteractivecreate);
                    }
                    break;
                }
              }}
              disabled={!configCheck.myinteractivecreate && !configCheck.globalinteractivecreate}
            >
              Create
            </Button>
          )}
        {(configCheck.myinteractiveedit ||
          configCheck.globalinteractiveedit ||
          configCheck.sharedinteractiveedit) &&
          id &&
          !props.location.pathname.includes('/duplicate') && (
            <Button
              className='footer-create-btn mx-2'
              id='global-lint'
              type='submit'
              onClick={(e) => {
                switch (type) {
                  case 'Button':
                    handleButtonEvent(id);
                    break;
                  default:
                    handleSubmit(e, false);
                    break;
                }
              }}
              disabled={!configCheck.myinteractiveedit && !configCheck.globalinteractiveedit}
            >
              Update
            </Button>
          )}
        {!configCheck.myinteractivecreate && configCheck.globalinteractivecreate && (
          <p className='global-note-message m-0 mt-1'>
            Note: This Interactive will create as Global
          </p>
        )}
      </div>
      <ModalComponent
        show={showModal}
        handleCancel={() => setShowModal(false)}
        modalDetails={modalType === 'cancel' ? getModalDetails('Exit') : createInteractiveModal()}
        confirmButton={{
          label: modalType === 'cancel' ? 'Exit' : 'Create',
          disabled: false,
          handleConfirm: (e: any) =>
            modalType === 'cancel'
              ? handleCancel()
              : type === 'Button'
              ? handleButtonEvent()
              : handleSubmit(e, saveType === 'global'),
        }}
        cancelButton={{
          label: modalType === 'cancel' ? 'Cancel' : 'Cancel',
        }}
      />
    </>
  );
}

//connecting form with redux
export default connect(mapStateToProps, mapDispatchToProps)(FormComponent);
