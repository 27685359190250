/* eslint-disable @typescript-eslint/no-explicit-any */
import { Editor } from '@tiptap/react';
import { TableViewConstructor } from '../editorExtensions/tableElementContent/tableView';

export interface LoreeInteractiveEditorType {
  initialValue: string;
  editorConfig: LoreeInteractiveEditorDashboardContentType;
  updateEditorContent: (value: string) => void;
  indexValue?: string;
}

export interface LintEditorFormatPropsType {
  editor: Editor | null | undefined;
  editorConfig?: LoreeInteractiveEditorDashboardContentType;
  fontFamilyName?: string;
  fontSizeValue?: FontSizeData;
}

export interface HeaderContentType {
  editor: Editor | null | undefined;
  editorConfig?: LoreeInteractiveEditorDashboardContentType;
  fontFamilyName?: string;
  fontSizeValue?: FontSizeData;
}

export interface MediaContentType {
  editor: Editor | null | undefined;
  setIsUploading: (value: boolean) => void;
}

export interface FontSizeData {
  size: number;
  unit: string;
}

export interface ValueAndUnit {
  value: string;
  unit: string;
}

export interface ListItemOptions {
  HTMLAttributes: Record<string, any>;
}
export interface CommonListOptions {
  itemTypeName: string;
  HTMLAttributes: Record<string, any>;
  keepMarks: boolean;
  keepAttributes: boolean;
}
export type WordOptionTextTransformType =
  | ''
  | 'uppercase'
  | 'lowercase'
  | 'capitalize'
  | 'strike'
  | 'superscript'
  | 'subscript';

export type BorderCollapseValues = 'collapse' | 'separate';

export type PaddingAttribute = 'paddingTop' | 'paddingRight' | 'paddingBottom' | 'paddingLeft';

export type BackgroundColorAttribute = 'backgroundColor';

export type FontCustomize =
  | 'fontFamily'
  | 'fontSize'
  | 'fontWeight'
  | 'oblique'
  | 'normal'
  | 'italic';

export interface ExtensionOptions {
  types: string[];
}

export interface EntireTextSelection {
  status: boolean;
  type: string;
}

export interface AlignmentButtonProps {
  activeLeft: boolean;
  activeCenter: boolean;
  activeRight: boolean;
  activeJustify: boolean;
  onLeft: () => void;
  onCenter: () => void;
  onRight: () => void;
  onJustify: () => void;
}
export interface TextIndentButtonProps {
  setIndentSpacing: (indentType: string, indentOption: string, typeName: string) => void;
  getLeftPadding: (typeName: string) => string;
  getTextIndent: (typeName: string) => string;
  selectionTypeName: string;
}

export interface ColumnResizingProps {
  handleWidth: number;
  cellMinWidth: number;
  nodeView: TableViewConstructor;
  lastColumnResizable: boolean;
}

export interface ColumnResizingMeta {
  setDragging?: { startX: number; startWidth: number };
  setHandle?: number;
}

export interface FontFamilyButtonProps {
  customFontList: CustomFontsType[];
  onFontFamilyChange: (value: string) => void;
  fontName?: string | null;
}

export interface LineHeightButtonProps {
  lineHeight?: string | null;
  onChangeLineHeight: (value: string) => void;
}

export type TableModalProps = {
  modalHeaderTitle: string;
  show: boolean;
  onHide: () => void;
  onSubmit?: (
    row: number,
    column: number,
    isHeaderRow: boolean,
    isHeaderColumn: boolean,
    captionValue: string,
  ) => void;
  onSubmitTableProperties?: (
    width: string,
    tableAlign: string,
    border: string,
    borderCollapse: string,
    backgroundColor: string,
  ) => void;
  onSubmitTableRowProperties?: (border: string, backgroundColor: string) => void;
  onSubmitTableCellProperties?: (
    padding: string,
    cellScope: string,
    border: string,
    backgroundColor: string,
  ) => void;
  styles?: {
    width?: string;
    alignment?: string;
    padding?: string;
    cellScope?: string;
    border: string;
    borderColor: string;
    borderStyle: string;
    borderCollapse: string;
    backgroundColor: string;
  };
};

export type CreateNewTableProps = {
  onHide: () => void;
  onSubmit?: (
    row: number,
    column: number,
    isHeaderRow: boolean,
    isHeaderColumn: boolean,
    captionValue: string,
  ) => void;
  onSubmitTableProperties?: (
    width: string,
    tableAlign: string,
    border: string,
    borderCollapse: string,
    backgroundColor: string,
  ) => void;
  isCellProperties?: boolean;
  onSubmitTableRowProperties?: (border: string, backgroundColor: string) => void;
  onSubmitTableCellProperties?: (
    padding: string,
    cellScope: string,
    border: string,
    backgroundColor: string,
  ) => void;
  styles?: {
    width?: string;
    alignment?: string;
    padding?: string;
    cellScope?: string;
    border: string;
    borderColor: string;
    borderStyle: string;
    borderCollapse: string;
    backgroundColor: string;
  };
};

export const TableModalTitleType = [
  'Add new table',
  'Cell Properties',
  'Row Properties',
  'Table Properties',
];

export const optionCollection = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10'];

export const borderStyles = ['Dashed', 'Dotted', 'Double', 'Solid'];

export const borderCollapseOption = ['Collapse', 'Separate'];

export const exceptionalKeys = [
  'Backspace',
  'ArrowUp',
  'ArrowDown',
  'ArrowLeft',
  'ArrowRight',
  'Control',
  'Tab',
  'Delete',
];

export const cellScopeOptions = ['None', 'Row', 'Column', 'Row group', 'Column group'];

export const tableCellTypes = ['tableCell', 'tableHeader'];

export interface TextColorButtonProps {
  onChange: (color: string, firstChange: boolean) => void;
  currentColor: string;
  globalColorList: string[];
  sharedColors: [];
  colorPickerFeatures: { [feature: string]: boolean };
  colorContrastRatio: () => void;
  colorPickerType: string;
}

export interface BackgroundColorButtonProps {
  onChange: (color: string, firstChange: boolean) => void;
  currentColor: string;
  globalColorList: string[];
  sharedColors: [];
  colorPickerFeatures: { [feature: string]: boolean };
  colorContrastRatio: () => void;
  colorPickerType: string;
}

export interface BorderColorButtonProps {
  onChange: (color: string, firstChange: boolean) => void;
  currentColor: string | null;
  currentSize: string | null;
  currentStyle: string | null;
  onChangeSize: (size: string) => void;
  onChangeStyle: (style: string) => void;
  globalColorList: string[];
  sharedColors: [];
  colorPickerFeatures: { [feature: string]: boolean };
  colorPickerType: string;
  colorContrastRatio: () => void;
}

export interface WebAddressProps {
  url: string;
  newTab: boolean;
  show: boolean;
  handleCancel: () => void;
  handleApply: (url: string, isNewTab: boolean) => void;
}

export interface LoreeInteractiveEditorDashboardContentType {
  customHeaderStyleList: CustomHeaderStyleType[];
  fontFamilyList: CustomFontsType[];
  customLinkStyleList: CustomLinksType[];
}

export interface CustomFontsType {
  name: string;
  url?: string;
  customFont?: string;
  customUrl?: string;
  key?: string;
  label?: string;
  fontFamilyValue?: string;
  fontStyle?: string;
}

export interface CustomHeaderStyleType {
  [Key: string]: { size: string; font: string };
}

export interface CustomLinksType {
  color: string;
  'text-decoration': string;
  'text-decoration-style': string;
  'text-style': string;
  font: string;
}
