import React from 'react';
import { Modal } from 'react-bootstrap';
import { TableModalProps, TableModalTitleType } from './lintEditorType';
import { CreateNewTableContent } from './tableModalContents/createTableModalContent';
import { TableContentProperties } from './tableModalContents/tableCellPropertiesContent';
import { TableProperties } from './tableModalContents/tablePropertiesContent';
import { ReactComponent as TableHeaderIcon } from '../../../assets/icons/tableHeaderIcon.svg';
import { ReactComponent as CellPropertyIcon } from '../../../assets/icons/cellProperityIcon.svg';
import { ReactComponent as RowPropertyIcon } from '../../../assets/icons/tableRowIcon.svg';
import { ReactComponent as TablePropertyIcon } from '../../../assets/icons/tablePropertyIcon.svg';
import { ReactComponent as CloseIcon } from '../../../assets/icons/loree-close.svg';
{
  /* type Placement = "right-start" | Side | "top-start" | "top-end" | "right-end" | "bottom-start" | "bottom-end" | "left-start" | "left-end" */
}

export const TableModal = (props: TableModalProps) => {
  const {
    modalHeaderTitle,
    show,
    onHide,
    onSubmit,
    onSubmitTableProperties,
    onSubmitTableRowProperties,
    onSubmitTableCellProperties,
    styles,
  } = props;

  const TableIconDiv = (Icon: React.ElementType, title: string) => {
    return (
      <div className='d-flex' style={{ gap: 8, alignItems: 'center' }}>
        <div
          style={{
            width: '24px',
            height: '24px',
            border: '1px solid rgb(233, 233, 233)',
            backgroundColor: 'rgb(244, 244, 244)',
            borderRadius: '8px',
            padding: '5px',
          }}
        >
          <Icon />
        </div>
        <span>{title}</span>
      </div>
    );
  };
  return (
    <>
      <Modal
        show={show}
        onHide={onHide}
        size='sm'
        backdrop='static'
        centered
        className='table-modal'
      >
        <Modal.Header className='tableModalHeader d-flex justify-content-between'>
          <Modal.Title>
            <h5 className='tableHeaderTitle'>
              {modalHeaderTitle === 'Add new table' ? (
                TableIconDiv(TableHeaderIcon, modalHeaderTitle)
              ) : modalHeaderTitle === 'Cell Properties' ? (
                <div>
                  <CellPropertyIcon style={{ marginRight: '10px' }} />
                  {modalHeaderTitle}
                </div>
              ) : modalHeaderTitle === 'Row Properties' ? (
                <div>
                  <RowPropertyIcon style={{ marginRight: '10px' }} />
                  {modalHeaderTitle}
                </div>
              ) : (
                <div>
                  <TablePropertyIcon style={{ marginRight: '10px' }} />
                  {modalHeaderTitle}
                </div>
              )}
            </h5>
          </Modal.Title>
          <div
            id='lint-custom-modal-close-icon'
            tabIndex={0}
            role='button'
            className='close-icon'
            onClick={onHide}
            onKeyDown={(e) => {
              if (e.key === 'Enter' || e.key === ' ') {
                onHide();
              }
            }}
          >
            <CloseIcon />
          </div>
        </Modal.Header>
        {modalHeaderTitle === TableModalTitleType[0] && (
          <CreateNewTableContent onSubmit={onSubmit} onHide={onHide} />
        )}
        {modalHeaderTitle === TableModalTitleType[1] && (
          <TableContentProperties
            onSubmitTableCellProperties={onSubmitTableCellProperties}
            onHide={onHide}
            styles={styles}
            isCellProperties
          />
        )}
        {modalHeaderTitle === TableModalTitleType[2] && (
          <TableContentProperties
            onSubmitTableRowProperties={onSubmitTableRowProperties}
            onHide={onHide}
            styles={styles}
          />
        )}
        {modalHeaderTitle === TableModalTitleType[3] && (
          <TableProperties
            onSubmitTableProperties={onSubmitTableProperties}
            onHide={onHide}
            styles={styles}
          />
        )}
      </Modal>
    </>
  );
};
