/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useCallback, useEffect, useState } from 'react';
import { Form, Modal } from 'react-bootstrap';
import { ReactComponent as CloseIcon } from '../../../assets/icons/loree-close.svg';
import { ReactComponent as LinkIcon } from '../editorFormattingIcons/linkButton.svg';
import { WebAddressProps } from './lintEditorType';

export const WebaddressModal = (props: WebAddressProps) => {
  const [inputValue, setInputValue] = useState('');
  const [btnDisabled, setBtnDisabled] = useState(true);
  const [newTab, setNewTab] = useState(props.newTab);
  const [isValid, setIsValid] = useState(false);
  const placeholderText = 'eg: https://soaringed.com';
  const urlValidate = useCallback((url: string) => {
    setIsValid(false);
    setInputValue(url.replace(/ +/g, ' '));
    setBtnDisabled(url.trim() === '');
  }, []);

  useEffect(() => {
    setNewTab(props.newTab);
    setInputValue(props.url);
    setIsValid(false);
  }, [urlValidate, props.show, props.newTab, props.url]);

  const validateExternalLink = (url: string) => {
    try {
      const isValidUrl = new URL(url);
      return isValidUrl ? isValidUrl.href : { error: 'null' };
    } catch (error) {
      return { error };
    }
  };

  const handleApplyBtn = () => {
    const validatedUrl = validateExternalLink(inputValue);
    if ((validatedUrl as { error: string }).error) {
      setIsValid(true);
      setBtnDisabled(true);
      return;
    }
    const formattedUrl = formatUrl(validatedUrl as string);
    setInputValue(formattedUrl);
    props.handleApply(formattedUrl, newTab);
  };

  const handleKeyDown = (event: { key: string; preventDefault: () => void }) => {
    if (event.key === 'Enter') {
      event.preventDefault();
    }
  };

  const handleCancel = () => {
    props.handleCancel();
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    urlValidate(event.target.value);
  };
  const formatUrl = (inputUrl: string) => {
    let formattedUrl = inputUrl.trim();
    const httpIndex = formattedUrl.indexOf('http//');
    const httpsIndex = formattedUrl.indexOf('https//');
    if (httpIndex !== -1) {
      formattedUrl = formattedUrl.replace('http//', '');
    } else if (httpsIndex !== -1) {
      formattedUrl = formattedUrl.replace('https//', '');
    }
    formattedUrl = formattedUrl.replace(/(https?:\/\/){2,}/g, '$1');
    if (!formattedUrl.startsWith('http://') && !formattedUrl.startsWith('https://')) {
      formattedUrl = 'https://' + formattedUrl;
    }
    if (formattedUrl.endsWith('/')) {
      formattedUrl = formattedUrl.replace(/\/$/, '');
    }
    return formattedUrl;
  };

  function RadioButtonGroup() {
    return (
      <div className='link-radiobutton-group footer-btn-control webaddress-container'>
        <p className='link-radio-label design-section-sub-text'>How do you want to open?</p>
        <div className='d-flex align-items-center gap-3'>
          <Form.Label
            onClick={() => {
              setNewTab(true);
            }}
            className='links-tab-label design-section-text d-flex align-items-center gap-2'
          >
            <input
              type='radio'
              name='tabTypeLabel'
              checked={newTab}
              onChange={() => {
                setNewTab(true);
              }}
            />
            New Tab
          </Form.Label>
          <Form.Label
            onClick={() => {
              setNewTab(false);
            }}
            className='links-tab-label design-section-text d-flex align-items-center gap-2'
          >
            <input
              type='radio'
              name='tabTypeLabel'
              checked={!newTab}
              onChange={() => {
                setNewTab(false);
              }}
            />
            Same Tab
          </Form.Label>
        </div>
      </div>
    );
  }

  return (
    <>
      <Modal
        id='web-address-modal'
        show={props.show}
        aria-labelledby='confirmation-popup'
        centered
        className='lint-custom-modal web-address-modal'
        backdrop='static'
      >
        <Modal.Header className='pb-2 d-flex justify-content-between'>
          <Modal.Title id='lint-custom-modal' className='d-flex align-items-center gap-2'>
            <div className='icon-background-container'>
              <LinkIcon />
            </div>
            <span>Add Link</span>
          </Modal.Title>
          <div id='lint-custom-modal-close-icon' tabIndex={0} role='button' onClick={handleCancel}>
            <CloseIcon />
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className='quick-links'>
            <div className='webaddress-container pb-3'>
              <p className='webaddress-label design-section-sub-text'>
                Type/ Paste your Web address below
              </p>
              <Form>
                <Form.Control
                  className='webaddress-input create-input-links'
                  data-testid='input-webaddress'
                  id='input-webaddress'
                  required
                  type='url'
                  placeholder={placeholderText}
                  autoComplete='off'
                  value={inputValue}
                  onChange={handleInputChange}
                  onKeyDown={handleKeyDown}
                />
                {isValid && (
                  <Form.Text className='text-danger'>Enter a valid web address</Form.Text>
                )}
              </Form>
            </div>
            <RadioButtonGroup />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            type='button'
            id='lint-custom-modal-cancel-button'
            className='btn btn-outline-primary editor-custom-cancel-button m-0'
            onClick={handleCancel}
          >
            Cancel
          </button>
          <button
            type='button'
            id='lint-custom-modal-apply-button'
            className='editor-btn-primary editor-custom-button btn btn-primary m-0'
            disabled={btnDisabled}
            onClick={handleApplyBtn}
          >
            Apply
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
