/* eslint-disable @typescript-eslint/no-explicit-any */
import { mergeAttributes, Node } from '@tiptap/core';
import { optimizeStyle } from './utilityFunctions';
import { ListItemOptions } from '../editorUtilityFunctions/lintEditorType';

export const ListItem = Node.create<ListItemOptions>({
  name: 'listItem',
  draggable: false,
  addOptions() {
    return {
      HTMLAttributes: {},
    };
  },

  content: 'block*',

  defining: true,
  addAttributes() {
    return {
      style: {
        default: 'text-align: left; font-size: 16px; color: #212529',
        parseHTML: (element) => {
          if (element.style && !element.style.getPropertyValue('color'))
            element.style.setProperty('color', '#212529');
          if (element.style && !element.style.getPropertyValue('font-size'))
            element.style.setProperty('font-size', '16px');
          return element.getAttribute('style');
        },
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: 'li',
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return [
      'li',
      optimizeStyle(mergeAttributes(this.options.HTMLAttributes, HTMLAttributes, {})),
      0,
    ];
  },

  addKeyboardShortcuts() {
    return {
      Enter: () => this.editor.commands.splitListItem(this.name),
      Tab: () => this.editor.chain().sinkListItems(this.name).run(),
      // eslint-disable-next-line @typescript-eslint/naming-convention
      'Shift-Tab': () => this.editor.chain().liftListItem(this.name).run(),
      // eslint-disable-next-line @typescript-eslint/naming-convention
      'Shift-Enter': () => this.editor.commands.setHardBreaks('shift'),
    };
  },
});
