/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import React, { useEffect, useState } from 'react';
import { Card, Row, Col, Container } from 'react-bootstrap';
import { Link, Redirect } from 'react-router-dom';
import Header from '../layouts/navbar';
import { userVerification } from '../../middleware/api';
import { initializeReactGA } from '../../middleware/googleAnalytics';
import './library.scss';
import CircleLoader from '../loader/loader';
import { setSharedAccountId } from '../../middleware/utils';
import { libraryDetails } from './libraryDetails';
import { getInteractiveTitle } from '../formComponent/utils';
import {
  getAdminDashboardFontFamilyList,
  getCustomHeaderFontDetails,
  getFeaturesList,
} from '../interactiveManagement/utils';
import IconsSidebar from '../layouts/sidebar';

const LibraryDashboard = (props: any) => {
  const [domainAuth, setDomainAuth] = useState(true);
  const [loading, setLoading] = useState(true);
  const [config, setConfig] = useState({});
  const [headerConfig, setHeaderConfig] = useState({});
  const [userId, setUserId] = useState('');
  const { user, id } = props.match.params;
  const { pathname, search } = props.location;
  //To differentiate the version of Loree
  const loreeVersion = props.match.params.version;
  sessionStorage.setItem('loreeVersion', loreeVersion);

  useEffect(() => {
    const fetchData = async () => {
      let check = await userVerification(user, id);
      setSharedAccountId(search);
      let config = check.featureConfig ? JSON.parse(check.featureConfig) : {};
      config = getFeaturesList(config, '');

      const featureConfig = JSON.parse(check.featureConfig ?? 'null');
      const fontFamilyList: Record<string, {}> = getAdminDashboardFontFamilyList(
        featureConfig.configsList,
      );
      const customHeaderStyleList: Record<string, {}> = getCustomHeaderFontDetails(
        featureConfig.configsList,
      );
      const customLinkStyleList = featureConfig.configsList?.customLinkStyle ?? {};
      sessionStorage.setItem('trackingId', check.gaID);

      //To check the tracking id of GA
      initializeReactGA(pathname);
      if (window.location === window.parent.location) {
        setDomainAuth(false);
      }

      setLoading(false);
      setUserId(check.user !== 'Not authorized' ? check.user : '');
      setConfig(config);
      setHeaderConfig({
        user: user,
        user_id: id,
        myinteractivecreate: config?.myinteractivecreate,
        globalinteractivecreate: config?.globalinteractivecreate,
        myinteractive: config?.myinteractive,
        globalinteractive: config?.globalinteractive,
        sharedinteractive: config?.sharedinteractive,
        myinteractiveedit: config?.myinteractiveedit,
        globalinteractiveedit: config?.globalinteractiveedit,
        sharedinteractiveedit: config?.sharedinteractiveedit,
        fontFamilyList: fontFamilyList?.fonts,
        customHeaderStyleList: customHeaderStyleList?.customHeader,
        customLinkStyleList: customLinkStyleList?.customLink,
      });
    };
    fetchData();
  }, [loading, user, id, search, pathname]);

  if (loading) return <CircleLoader loaderType={''} />;
  if ((!loading && userId === '') || !domainAuth) return <Redirect to='/401' />;
  else {
    return (
      <>
        <Container
          fluid
          className='margin pt-4 landingPageLoreeInteractive'
          id='interactiveContainer'
        >
          <Row>
            {/* Cards */}
            <Col md={11} lg={11} id='interactivesColumn'>
              <Row xs={1} md={2} lg={4} className='g-4'>
                {libraryDetails.map((component) => {
                  return (
                    <Col key={component.title}>
                      <Card
                        bsPrefix='card card-style'
                        className='rounded-lg h-100'
                        id='interactiveCards'
                      >
                        <Card.Img
                          src={component.src}
                          alt={component.alt}
                          className='img-fluid p-3'
                        />
                        <Card.Body
                          bsPrefix='card-body title-body mx-3'
                          className='p-0'
                          id='interactiveBody'
                        >
                          <Card.Title className='text-color' id='interactiveTitle'>
                            {getInteractiveTitle(component.title)}
                          </Card.Title>
                          <Card.Text bsPrefix='card-text description-text'>
                            {component.description}
                          </Card.Text>
                        </Card.Body>
                        <Card.Footer
                          className='bg-white border-0 text-center'
                          id='interactiveFooter'
                        >
                          <Link
                            to={{
                              pathname: `${component.pathName}`,
                              state: { config: headerConfig },
                            }}
                            className='btn btn-sm btn-primary card-create-btn'
                            id='interactiveCreateBtn'
                          >
                            Create
                          </Link>
                        </Card.Footer>
                      </Card>
                    </Col>
                  );
                })}
              </Row>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
};

export default LibraryDashboard;
